import { useApi } from '~/composables/api/useApi'

export function useLabels() {
  const { fetchWithAuth } = useApi()
  const RESSOURCE = '/labels'

  // GET Requests

  interface FetchLabelsOptions {
    type: string
    userid: number
    id: number
    labelId: number
    index: number
    nValues: number
    sortColumn: string
    sortDirection: string
    globals: boolean
  }

  async function fetchLabels(providedOptions: FetchLabelsOptions) {
    return await fetchWithAuth(RESSOURCE, 'GET', {
      providedOptions,
    })
  }

  // POST Requests

  async function assignLabelToDocuments(providedOptions: {
    labelId: number
    documents: Array<{ docId: number }>
  }) {
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.labelId}/documents`,
      'POST',
      {
        data: providedOptions.documents,
      }
    )
  }

  // DELETE Requests

  async function removeLabelFromDocuments(providedOptions: {
    labelId: number
    documents: Array<{ docId: number }>
  }) {
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.labelId}/documents`,
      'DELETE',
      {
        data: providedOptions.documents,
      }
    )
  }

  async function assignLabelToPages(providedOptions: {
    labelId: number
    pages: Array<{ pageId: number }>
    docId: number
  }) {
    let data = {
      docId : providedOptions.docId,
      pageList : {
        pages: providedOptions.pages
      }
    }

    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.labelId}/pages`,
      'POST',
      {
        data: [data],
      }
    )
  } 

  async function removeLabelFromPages(providedOptions: {
    labelId: number
    pages: Array<{ pageId: number }>
    docId: number
  }) {
    let data = {
      docId : providedOptions.docId,
      pageList : {
        pages: providedOptions.pages
      }
    }

    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.labelId}/pages`,
      'DELETE',
      {
        data: [data],
      }
    )
  }

  return {
    fetchLabels,
    assignLabelToDocuments,
    removeLabelFromDocuments,
    assignLabelToPages,
    removeLabelFromPages
  }
}
