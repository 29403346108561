<template>
  <div class="app-slide-over__wrapper" @click="$emit('clicked')">
    <!-- Header -->
    <div class="app-slide-over__header">
      <h3
        v-if="isSitesDoc || isSitesPage"
        :class="`app-sitesfooter__link ${rsCollection?.config?.theme} app-title`"
      >
        {{ $translate('Metadata', language, rsCollection.locales) }}
      </h3>
      <h3 v-else-if="pageNr" class="app-title">{{ $t('Metadata') }}</h3>
      <h3 v-else class="app-title">{{ $t('Document settings') }}</h3>
    </div>
    <!-- Body -->
    <div v-if="isJob">
      <div class="app-card__table mt-4">
        <div v-for="(value, key, index) in job" class="app-card__table__row">
          <div class="app-card__table__data">
            <!-- if we really want icons we could try and make something for keys to map to -->
            <!-- <i class="mdi mdi-numeric"></i> -->
            {{ key }}
          </div>
          <div class="app-card__table__data">
            {{ value }}
          </div>
        </div>
      </div>
    </div>
    <div class="app-slide-over__body max-w-full" v-if="itemInfo">
      <div v-if="isSitesDoc">
        <div class="app-card__table mt-4">
          <div
            v-for="(value, key, index) in itemInfo"
            class="app-card__table__row"
            v-if="
              ![
                'uploadTimestamp',
                'uploaderId',
                'status',
                'fimgStoreColl',
                'createdFromTimestamp',
                'origDocId',
                'collectionList',
                'mainColId',
                'type',
                'attributes',
              ].includes(key)
            "
          >
            <div
              v-if="key === 'thumbUrl'"
              class="even:text-right text-gray-300"
            >
              <!-- <i class="mdi mdi-numeric"></i> -->
              {{
                $translate(
                  key.charAt(0).toUpperCase() + key.slice(1),
                  language,
                  rsCollection.locales
                )
              }}
            </div>
            <div v-else class="app-card__table__data">
              <!-- <i class="mdi mdi-numeric"></i> -->
              {{
                $translate(
                  key.charAt(0).toUpperCase() + key.slice(1),
                  language,
                  rsCollection.locales
                )
              }}
            </div>
            <div class="app-card__table__data">
              {{ value }}
            </div>
          </div>
        </div>

        <div
          v-if="itemInfoAttr"
          v-for="(item, index) in itemInfoAttr"
          class="app-card__table mt-4"
        >
          <div class="app-card__table__row-subheading">
            <div class="app-card__table__subheading">
              <!-- <i class="mdi mdi-numeric"></i> -->
              Custom Attribute #{{ index + 1 }}
            </div>
          </div>
          <div class="app-card__table__row" v-for="(value, key, i) in item">
            <div class="app-card__table__data">
              <!-- <i class="mdi mdi-numeric"></i> -->
              {{ key.charAt(0).toUpperCase() + key.slice(1) }}
            </div>
            <div class="app-card__table__data">
              {{ value }}
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="isSitesPage">
        <div class="app-card__table mt-4">
          <div
            v-for="(value, key, index) in itemInfo"
            class="app-card__table__row"
          >
            <div class="app-card__table__data">
              <!-- if we really want icons we could try and make something for keys to map to -->
              <!-- <i class="mdi mdi-numeric"></i> -->
              {{ $translate(key, language, rsCollection.locales) }}
            </div>
            <div class="app-card__table__data">
              {{ value }}
            </div>
          </div>
        </div>
        <!--  Since now no metadata can be set in the editor I removed this (Johannes) -->
        <!-- <div class="app-card__table" v-if="browserStore.metaData">
          <div class="app-card__table__row-subheading">
            <div class="app-card__table__subheading">Descriptive Metadata</div>
          </div>
          <div
            v-for="child in browserStore.metaData.children"
            class="app-card__table__row"
          >
            <div class="app-card__table__data">
              {{ child.attributes.key }}
            </div>
            <div class="app-card__table__data">
              {{ child.attributes.value }}
            </div>
          </div>
        </div> -->
      </div>
      <div v-else>
        <div
          class="flex gap-2 flex-col"
          v-if="itemInfo.type === 'trpDocMetadata'"
        >
          <BaseInput
            :disabled="!permissions.doc?.editMetadata"
            :id="'drawer-title'"
            :name="'drawer-title'"
            :label="'Title'"
            :value="itemInfo.title"
            @input="itemInfo.title = $event.target.value"
          ></BaseInput>
          <TailwindComponentsCommandPalette
            v-if="!loadingLabels"
            :disabled="!permissions.doc?.manageLabels"
            :placeholder="'Select labels'"
            :label="'Labels'"
            :items="showLabels"
            :objValue="'name'"
            :listType="'collection'"
            :initialSelected="documentLabels"
            selectable
            @search="findLabels"
            @selected="option => (selectedLabels = option)"
            customList
            show-selected-below
          >
            <template v-slot:selected-item="{ selectedItem }">
              <span class="pr-2">{{ selectedItem.name }}</span>
            </template>
            <template v-slot:item="{ item }">
              <div class="app-command-palette__content">
                <div class="app-command-palette__content__text">
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </template>
          </TailwindComponentsCommandPalette>

          <div>
            <!-- Advanced settings -->
            <TailwindComponentsCollapse no-padding>
              <template #header>{{ $t('Advanced settings') }}</template>
              <template #content>
                <BaseInput
                  :disabled="!permissions.doc?.editMetadata"
                  :id="'drawer-author'"
                  :name="'drawer-author'"
                  :label="'Author'"
                  :value="itemInfo.author"
                  @input="itemInfo.author = $event.target.value"
                >
                </BaseInput>
                <BaseInput
                  :disabled="!permissions.doc?.editMetadata"
                  :id="'drawer-writer'"
                  :name="'drawer-writer'"
                  :label="'Writer'"
                  :value="itemInfo.writer"
                  @input="itemInfo.writer = $event.target.value"
                >
                </BaseInput>
                <BaseTextarea
                  :disabled="!permissions.doc?.editMetadata"
                  :label="'Description'"
                  :id="'drawer-description'"
                  :name="'drawer-description'"
                  :value="itemInfo.desc"
                  @input="itemInfo.desc = $event.target.value"
                ></BaseTextarea>
                <BaseInput
                  :disabled="!permissions.doc?.editMetadata"
                  :id="'drawer-genre'"
                  :name="'drawer-genre'"
                  :label="'Genre'"
                  :value="itemInfo.genre"
                  @input="itemInfo.genre = $event.target.value"
                ></BaseInput>

                <FilterLanguage
                  :disabled="!permissions.doc?.editMetadata"
                  @selected="setLanguages"
                  :initFilter="languageArr"
                  :customLanguage="false"
                  :label="'Languages'"
                  :icon="'label'"
                />

                <BaseInput
                  :disabled="!permissions.doc?.editMetadata"
                  :id="'drawer-hierarchy'"
                  :name="'drawer-hierarchy'"
                  :label="'Hierarchy (Split hierarchy levels with / )'"
                  :value="itemInfo.hierarchy"
                  @input="itemInfo.hierarchy = $event.target.value"
                ></BaseInput>
                <BaseInput
                  :disabled="!permissions.doc?.editMetadata"
                  :id="'drawer-authority'"
                  :name="'drawer-authority'"
                  :label="'Authority'"
                  :value="itemInfo.authority"
                  @input="itemInfo.authority = $event.target.value"
                ></BaseInput>
                <BaseInput
                  :disabled="!permissions.doc?.editMetadata"
                  :id="'drawer-externalId'"
                  :name="'drawer-externalId'"
                  :label="'External ID'"
                  :value="itemInfo.externalId"
                  @input="itemInfo.externalId = $event.target.value"
                ></BaseInput>
                <BaseInput
                  :disabled="!permissions.doc?.editMetadata"
                  :id="'drawer-backlink'"
                  :name="'drawer-backlink'"
                  :label="'Backlink'"
                  :value="itemInfo.backlink"
                  @input="itemInfo.backlink = $event.target.value"
                ></BaseInput>
                <BaseSelect
                  :disabled="!permissions.doc?.editMetadata"
                  :name="'drawer-script-type'"
                  :id="'drawer-script-type'"
                  :label="'Script type'"
                  :placeholder="'Select a script type'"
                  :options="[
                    {
                      name: 'Handwritten',
                      value: 'HANDWRITTEN',
                    },
                    { name: 'Printed', value: 'NORMAL' },
                  ]"
                  :value="itemInfo.scriptType"
                  @selectionChange="itemInfo.scriptType = $event"
                ></BaseSelect>
                <label
                  class="inline-block text-sm font-medium leading-6 text-primaryG-700 mb-0 pt-2"
                  >{{ $t('Date of composition') }}</label
                >
                <div class="flex flex-row">
                  <BaseInput
                    class="mr-4"
                    type="date"
                    :label="'From'"
                    :value="formatInputDate(itemInfo.createdFromTimestamp)"
                    @input="
                      itemInfo.createdFromTimestamp = makeDateInt(
                        $event.target.value
                      )
                    "
                  ></BaseInput>
                  <BaseInput
                    type="date"
                    :label="'To'"
                    :value="formatInputDate(itemInfo.createdToTimestamp)"
                    @input="
                      itemInfo.createdToTimestamp = makeDateInt(
                        $event.target.value
                      )
                    "
                  ></BaseInput>
                </div>
              </template>
            </TailwindComponentsCollapse>

            <div
              class="flex-col gap-y-6"
              v-if="
                itemInfo &&
                itemInfo.type === 'trpDocMetadata' &&
                !isSitesDoc &&
                !isSitesDoc
              "
            >
              <div class="app-card__table mt-4">
                <div class="app-card__table__row">
                  <div class="app-card__table__data">
                    <i class="mdi mdi-numeric"></i>
                    {{ $t('Document ID') }}
                  </div>
                  <div class="app-card__table__data">
                    {{ itemInfo.docId }}
                  </div>
                </div>
                <div class="app-card__table__row">
                  <div class="app-card__table__data">
                    <i class="mdi mdi-account"></i>
                    {{ $t('Uploader') }}
                  </div>
                  <div class="app-card__table__data">
                    {{ itemInfo.uploader }}
                  </div>
                </div>
                <div class="app-card__table__row">
                  <div class="app-card__table__data">
                    <i class="mdi mdi-clock"></i>
                    {{ $t('Upload-Date') }}
                  </div>
                  <div class="app-card__table__data">
                    <BaseDate :value="itemInfo.uploadTimestamp" showTime />
                  </div>
                </div>
                <div class="app-card__table__row">
                  <div class="app-card__table__data">
                    <i class="mdi mdi-clipboard-list"></i>
                    {{ $t('Collection-List') }}
                  </div>
                  <div class="app-card__table__data">
                    {{
                      itemInfo.collectionList.colList
                        .map(col => col.colName)
                        .join()
                    }}
                  </div>
                </div>
                <div class="app-card__table__row">
                  <div class="app-card__table__data">
                    <i class="mdi mdi-book-open"></i>
                    {{ $t('Nr of Pages') }}
                  </div>
                  <div class="app-card__table__data">
                    {{ itemInfo.nrOfPages }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="itemInfo.type === 'trpTranscriptMetadata'">
          <div class="app-card__table">
            <div class="app-card__table__row" v-if="page?.imgFileName">
              <div class="app-card__table__data">
                {{ $t('File name') }}
              </div>
              <div class="app-card__table__data">
                {{ page?.imgFileName }}
              </div>
            </div>
            <div class="app-card__table__row" v-if="page?.fileSize">
              <div class="app-card__table__data">
                {{ $t('File size') }}
              </div>
              <div class="app-card__table__data">
                {{ parseInt(page?.fileSize / 1000) + ' KB' }}
              </div>
            </div>
            <div class="app-card__table__row" v-if="page?.created">
              <div class="app-card__table__data">
                {{ $t('Date created') }}
              </div>
              <div class="app-card__table__data">
                {{ parseDate(page?.created) }}
              </div>
            </div>
            <div class="app-card__table__row" v-if="page?.lastModified">
              <div class="app-card__table__data">
                {{ $t('Last saved') }}
              </div>
              <div class="app-card__table__data">
                {{ parseDate(page?.lastModified) }}
              </div>
            </div>
            <div
              class="app-card__table__row"
              v-if="page?.tsList.transcripts[0].userName"
            >
              <div class="app-card__table__data">
                {{ $t('Last edited by') }}
              </div>
              <div class="app-card__table__data">
                {{ page?.tsList.transcripts[0].userName }}
              </div>
            </div>
            <div class="app-card__table__row" v-if="itemInfo.toolName">
              <div class="app-card__table__data">
                {{ $t('Toolname') }}
              </div>
              <div class="app-card__table__data">
                {{ itemInfo.toolName }}
              </div>
            </div>
            <div class="app-card__table__row" v-if="itemInfo.status">
              <div class="app-card__table__data">
                {{ $t('Status') }}
              </div>
              <div class="app-card__table__data">
                {{ itemInfo.status }}
              </div>
            </div>
            <div class="app-card__table__row" v-if="itemInfo.nrOfRegions">
              <div class="app-card__table__data">
                {{ $t('Regions') }}
              </div>
              <div class="app-card__table__data">
                {{ itemInfo.nrOfRegions }}
              </div>
            </div>
            <div class="app-card__table__row" v-if="itemInfo.nrOfLines">
              <div class="app-card__table__data">
                {{ $t('Lines') }}
              </div>
              <div class="app-card__table__data">
                {{ itemInfo.nrOfLines }}
              </div>
            </div>
            <div class="app-card__table__row" v-if="itemInfo.nrOfWords">
              <div class="app-card__table__data">
                {{ $t('Words') }}
              </div>
              <div class="app-card__table__data">
                {{ itemInfo.nrOfWords }}
              </div>
            </div>
            <div class="app-card__table__row" v-if="itemInfo.url">
              <div class="app-card__table__data">
                {{ $t('XML') }}
              </div>
              <div class="app-card__table__data">
                <a :href="itemInfo.url" target="_blank">
                  <i class="mdi mdi-download"></i>
                  <span>{{ $t('Download') }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer -->

    <div
      class="app-slide-over__footer gap-x-4 flex fixed bottom-0 right-0 w-full py-6 items-end bg-white sticky"
    >
      <BaseButton
        v-if="permissions.doc?.editMetadata"
        :label="'Cancel'"
        for="edit-metadata"
        :type="'secondary'"
        :loading="isLoading"
      ></BaseButton>
      <BaseButton
        v-if="permissions.doc?.editMetadata"
        :label="'Save'"
        @click="saveMetadata(itemInfo)"
        :loading="isLoading"
      ></BaseButton>
    </div>
  </div>
</template>

<script>
import languageData from '~/assets/language-codes.json'
import Confirmation from '../Modals/Confirmation.vue'
import { mapState } from 'pinia'
import { useSiteStore } from '~/stores/sites'

export default {
  components: {
    Confirmation,
  },
  setup() {
    const permissions = usePermissions()
    const collectionsApi = useCollections()
    const sitesApi = useSites()
    const labelsApi = useLabels()
    return {
      collectionsApi,
      sitesApi,
      permissions,
      labelsApi,
    }
  },
  data() {
    return {
      showTime: false,
      itemInfo: null,
      itemInfoAttr: [],
      filteredLanguages: [],
      languages: [],
      languageArr: [],
      isLoading: false,
      labels: [],
      showLabels: [],
      selectedLabels: [],
      documentLabels: [],
      initialLabels: [],
      loadingLabels: false,
    }
  },
  props: {
    isSitesDoc: {
      type: Boolean,
      default: false,
    },
    isSitesPage: {
      type: Boolean,
      default: false,
    },
    isJob: {
      type: Boolean,
      default: false,
    },
    job: Object,
    page: Object,
    url: String,
    doc: Object,
    collId: Number,
    docId: Number,
    pageNr: Number,
  },
  computed: {
    ...mapState(useSiteStore, ['language', 'rsCollection']),
    currentLocale() {
      let lang = this.$i18n.locales
        .filter(i => i.code === this.$i18n.locale)
        .pop()

      if (!languageData[0][lang.name]) {
        lang = { code: 'en', iso: 'en', name: 'English', label: 'EN' }
      }
      return lang
    },
  },
  async mounted() {
    if (!this.isJob) {
      await this.getMeta()
    }
    if (!this.isSitesDoc && !this.isSitesPage && !this.pageNr) {
      this.loadingLabels = true
      await this.getLabels()
      await this.getLabelsOfDocument()
      this.loadingLabels = false
    }
  },
  watch: {
    docId() {
      this.getMeta()
      this.loadingLabels = true
      this.getLabels()
      this.getLabelsOfDocument()
      this.loadingLabels = false
    },
    pageNr() {
      this.getMeta()
    },
  },
  methods: {
    parseDate(value) {
      if (value) {
        const date = new Date(value)

        if (date.toLocaleString() !== 'Invalid Date') {
          return date.toLocaleString('en-US')
        } else {
          return 'Invalid Date'
        }
      } else {
        return
      }
    },
    async getLabelsOfDocument() {
      if (this.doc) {
        this.documentLabels = [...this.doc.labels]
        this.initialLabels = [...this.doc.labels]
      } else {
        try {
          const response = await this.labelsApi.fetchLabels({
            id: this.docId,
          })

          this.documentLabels = [...response.trpLabel]
          this.initialLabels = [...response.trpLabel]
        } catch (error) {
          this.$sentry.captureException(error)
        }
      }
    },
    findLabels(search) {
      if (!search || typeof search !== 'string' || search === '') {
        this.showLabels = this.labels
      } else {
        this.showLabels = this.labels.filter(label =>
          label.name.includes(search)
        )
      }
    },
    async getLabels() {
      try {
        const response = await this.labelsApi.fetchLabels()

        this.labels = response.trpLabel
        this.showLabels = this.labels
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    async getMeta() {
      let data
      if (this.isSitesDoc) {
        data = await this.sitesApi.fetchSitesDocMeta({
          id: this.docId,
          url: this.url,
          collection: this.collId,
        })
      } else if (this.isSitesPage) {
        let collectionID = this.collId[0]
        if (this.collId.length > 1) {
          const data = await this.sitesApi.fetchRSPageByNum({
            id: this.docId,
            pageNum: this.pageNr,
            collection: this.collId,
            url: this.url,
          })
          collectionID = data?.pageData?.colId[0]
        }

        data = await this.sitesApi.fetchSitesPageMeta({
          id: this.docId,
          pageNum: this.pageNr,
          url: this.url,
          collection: collectionID,
        })
      } else if (this.pageNr) {
        try {
          data = await this.collectionsApi.fetchPageMeta({
            docId: this.docId,
            collId: this.collId,
            pageNr: this.pageNr,
          })
        } catch (err) {
          this.$sentry.captureException(err)
        }
      } else {
        try {
          data = await this.collectionsApi.fetchDocumentsMeta({
            docId: this.docId,
            collId: this.collId,
          })
        } catch (err) {
          this.$sentry.captureException(err)
        }
      }
      this.itemInfo = data

      if (this.itemInfo.attributes?.length > 0) {
        this.itemInfoAttr = this.itemInfo.attributes
      }

      if (this.itemInfo.language) {
        this.languages = this.itemInfo.language.split(',')

        this.languageArr = []
        languageData.forEach(language => {
          if (this.languages.includes(language.English)) {
            this.languageArr.push(language)
          }
        })
      } else {
        this.languageArr = []
      }
    },
    showLanguageWarning(shortcut) {
      this.modal = {
        modalContent: {
          component: Confirmation,
          props: {
            title: {
              text:
                this.shareType === 'Document'
                  ? this.$t('Remove Shortcut')
                  : this.$t('Remove model from collection'),
            },
            text:
              this.shareType === 'Document'
                ? this.$t('Are you sure want to remove shortcut')
                : this.$t(
                    'Are you sure want to remove the model from this collection'
                  ) + shortcut?.colName,
            type: 'danger',
            button: {
              label: 'Remove',
              icon: 'delete',
            },
          },
          events: {
            confirm: () => {
              this.removeShortcut()
            },
          },
        },
      }
      this.$bus.emit('open-modal', { modal: this.modal })
    },
    showLanguageWarning(tag) {
      let local = this.currentLocale.name
      if (tag.alpha2) {
        return tag
      } else {
        this.modal = {
          modalContent: {
            component: Confirmation,
            props: {
              title: {
                text:
                  this.$t('Are you sure you want to add a custom language ') +
                  tag,
              },
              type: 'success',
              button: {
                label: 'Confirm',
                icon: 'add',
              },
            },
            events: {
              confirm: () => {
                this.languages.push({ [local]: tag, English: tag })
              },
            },
          },
        }
        this.$bus.emit('open-modal', { modal: this.modal })
      }
    },
    getFilteredLanguage(text) {
      this.filteredLanguages = languageData.filter(option => {
        var values = Object.values(option)
        var flag = false
        values.forEach(val => {
          if (val.toLowerCase().indexOf(text.toLowerCase()) >= 0) {
            flag = true
            return
          }
        })
        if (flag) return option
      })
    },
    setLanguages(value) {
      this.languages = value
    },
    async saveMetadata(value) {
      this.isLoading = true

      const newLabels = this.selectedLabels.filter(
        label =>
          !this.initialLabels.find(
            docLabel => docLabel.labelId === label.labelId
          )
      )
      const removedLabels = this.initialLabels.filter(
        docLabel =>
          !this.selectedLabels.find(label => label.labelId === docLabel.labelId)
      )

      if (newLabels.length > 0) {
        try {
          for (const label of newLabels) {
            await this.labelsApi.assignLabelToDocuments({
              labelId: label.labelId,
              documents: [{ docId: this.docId }],
            })
          }
        } catch (error) {
          this.$sentry.captureException(error)
          this.$bus.emit('notification', {
            message: 'Labels could not be saved',
            type: 'error',
          })
        }
      }

      if (removedLabels.length > 0) {
        try {
          for (const label of removedLabels) {
            await this.labelsApi.removeLabelFromDocuments({
              labelId: label.labelId,
              documents: [{ docId: this.docId }],
            })
          }
        } catch (error) {
          this.$sentry.captureException(error)
          this.$bus.emit('notification', {
            message: 'Labels could not be removed',
            type: 'error',
          })
        }
      }

      this.languages = this.languages.map(lang => {
        if (typeof lang === 'object') {
          return lang.English
        }
        return lang
      })
      value.language = this.languages.join()
      try {
        await this.collectionsApi.updateDocumentsMeta({
          docId: this.docId,
          collId: this.collId,
          metadata: value,
        })
        this.$bus.emit('notification', {
          message: this.$t('Metadata was saved'),
          type: 'success',
        })
        // Close Modal
        this.$bus.emit('slideOverClose')

        // reload document
        /* if (
          this.docId == this.$route.params.docid &&
          !this.$route.query.pageid
        ) {
          this.$router.go(0)
        } */
        this.$bus.emit('loadDocument')
        this.$emit('saved')
      } catch (err) {
        this.$bus.emit('notification', {
          message: this.$t('Metadata save failed'),
          type: 'error',
        })
        this.$sentry.captureException(err)
      }
      this.isLoading = false
    },
    formatInputDate(dateInt) {
      const date = new Date(dateInt)

      const year = date.getUTCFullYear()
      const month = String(date.getUTCMonth() + 1).padStart(2, '0')
      const day = String(date.getUTCDate()).padStart(2, '0')

      return `${year}-${month}-${day}`
    },
    makeDateInt(val) {
      const date = new Date(val)
      return date.getTime()
    },
  },
}
</script>
